import { useState, useEffect, createRef } from 'react';
import { useNavigate } from "react-router-dom";


import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    Typography,
    TextField,
    CircularProgress,
    Tooltip,
    Snackbar, Alert,
    Modal
} from '@mui/material';

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



import Logo from "assets/images/Icon-Dark-1024.png";


// import { AccountProfile } from '../components/account/account-profile';
// import { AccountProfileDetails } from '../components/account/account-profile-details';

import MDBox from "components/MDBox";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// import { useDropzone } from 'react-dropzone';

import { StyledDropzone } from 'components/dropzone';
import MDButton from 'components/MDButton';

import { SUBJECTS } from "constants"

// Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, onSnapshot, setDoc, collection, getDocs, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { PATH } from 'constants';

import moment from "moment";

import { parseSubmissionStatus } from "utils";
import { SUBMISSION_STATUS } from 'constants';

const validationFileInputRef = createRef()
const paper1FileInputRef = createRef()
const paper2FileInputRef = createRef()
const paperFileInputRef = createRef()

const dateTimeDisplayFormat = "D MMM HH:mm"

const FILE_SOURCE = {
    PROOF: 'proof',
    PAPER1: 'paper1',
    PAPER2: 'paper2'
}

const PROOF_SIZE_LIMIT = 5000000


export function Submission() {

    const navigate = useNavigate()

    const [profileConfirmed, setProfileConfirmed] = useState(false)
    const [initialized, setInitialized] = useState(false)

    const auth = getAuth();
    const user = auth.currentUser;
    let uid = user ? user.uid : null;

    // Firestore
    const db = getFirestore();

    const userDocRef = doc(db, 'users', uid)

    useEffect(() => {
        // getDoc(userDocRef)
        // .then()
        const unsub = onSnapshot(userDocRef, (docSnap) => {
            if (docSnap.exists()) {

                const data = docSnap.data()
                if (data['studentID']) {
                    setProfileConfirmed(true)
                }
            }

            setInitialized(true)

        })

        return () => { unsub() }
    }, [])

    // Submission Status
    const [coreSubmissionStatus, setCoreSubmissionStatus] = useState(null)
    const [m1SubmissionStatus, setM1SubmissionStatus] = useState(null)
    const [m2SubmissionStatus, setM2SubmissionStatus] = useState(null)

    const timeframeColRef = "timeframe"

    useEffect(() => {
        const unsub = onSnapshot(collection(db, timeframeColRef), (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id, doc.data())

                console.log("Submission data: ", doc.id, doc.data())

                const submissionData = parseSubmissionStatus(doc.data())

                switch (doc.id) {
                    case SUBJECTS.CORE:
                        setCoreSubmissionStatus(submissionData)
                        if (tabValue === 0) {
                            loadSubmissionData(submissionData)
                        }
                        break
                    case SUBJECTS.M1:
                        setM1SubmissionStatus(submissionData)
                        if (tabValue === 1) {
                            loadSubmissionData(submissionData)
                        }
                        break
                    case SUBJECTS.M2:
                        setM2SubmissionStatus(submissionData)
                        if (tabValue === 2) {
                            loadSubmissionData(submissionData)
                        }
                        break
                    default:
                        break
                }
            })
        })

        return () => { unsub() }

    }, [])

    // Values
    const [submissionStatus, setSubmissionStatus] = useState(null)
    const [submissionEnabled, setSubmissionEnabled] = useState(true)
    const [validationRecord, setValidationRecord] = useState(null)
    const [paper1SubmissionRecord, setPaper1SubmissionRecord] = useState(null)
    const [paper2SubmissionRecord, setPaper2SubmissionRecord] = useState(null)
    const [paper2ChoicesSubmissionRecord, setPaper2ChoicesSubmissionRecord] = useState(null)

    // Choose Subject
    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const [coreData, setCoreData] = useState(null)
    const [m1Data, setM1Data] = useState(null)
    const [m2Data, setM2Data] = useState(null)


    const [paper2Choices, setPaper2Choices] = useState(Array(45).fill(-1))
    const [validationSubmissionEnabled, setValidationSubmissionEnabled] = useState(true)
    const [paper1SubmissionEnabled, setPaper1SubmissionEnabled] = useState(true)
    const [paper2SubmissionEnabled, setPaper2SubmissionEnabled] = useState(true)
    const [paper2ChoicesSubmissionEnabled, setPaper2ChoicesSubmissionEnabled] = useState(true)


    const [submissionCompleted, setSubmissionCompleted] = useState(false)
    const [submissionIncomplete, setSubmissionIncomplete] = useState(false)

    useEffect(() => {

        switch (tabValue) {
            case 0:
                // Core
                if (validationRecord && paper1SubmissionRecord && paper2SubmissionRecord && paper2ChoicesSubmissionRecord) {
                    setSubmissionCompleted(true)
                    setSubmissionIncomplete(false)
                } else {
                    if (validationRecord || paper1SubmissionRecord || paper2ChoicesSubmissionRecord || paper2ChoicesSubmissionRecord) {
                        setSubmissionIncomplete(true)
                    } else {
                        setSubmissionIncomplete(false)

                    }
                    setSubmissionCompleted(false)
                }
                break
            default:
                // M1, M2
                if (validationRecord && paper1SubmissionRecord) {
                    setSubmissionCompleted(true)
                    setSubmissionIncomplete(false)
                } else {
                    if (validationRecord || paper1SubmissionRecord) {
                        setSubmissionIncomplete(true)
                    } else {
                        setSubmissionIncomplete(false)

                    }
                    setSubmissionCompleted(false)
                }

                break
        }

    }, [tabValue, validationRecord, paper1SubmissionRecord, paper2SubmissionRecord, paper2ChoicesSubmissionRecord])


    // Listener for each subject
    useEffect(() => {
        const sub = onSnapshot(collection(db, `users/${uid}/submissions`), (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id, doc.data())


                switch (doc.id) {
                    case SUBJECTS.CORE:
                        setCoreData(doc.data())

                        if (tabValue === 0) {
                            loadSubjectData(doc.data())
                        }
                        break
                    case SUBJECTS.M1:
                        setM1Data(doc.data())
                        if (tabValue === 1) {
                            loadSubjectData(doc.data())
                        }
                        break
                    case SUBJECTS.M2:
                        setM2Data(doc.data())
                        if (tabValue === 2) {
                            loadSubjectData(doc.data())
                        }
                        break
                    default:
                        break
                }



            })
        })


        return () => sub
    }, [])

    // useEffect(() => {
    //     const sub = onSnapshot(doc(db, `users/${uid}/submissions/${SUBJECTS.CORE}`), (doc) => {
    //         console.log("Snapshot data: ", doc.id, doc.data())
    //         setCoreData(doc.data())
    //     })

    //     if (tabValue === 0) { loadSubjectData() }

    //     return () => sub
    // }, [])

    useEffect(() => {
        setValidationSubmissionEnabled(submissionEnabled && !validationRecord)
    }, [submissionEnabled, validationRecord])

    useEffect(() => {
        setPaper1SubmissionEnabled(submissionEnabled && !paper1SubmissionRecord)
    }, [submissionEnabled, paper1SubmissionRecord])


    useEffect(() => {
        setPaper2SubmissionEnabled(submissionEnabled && !paper2SubmissionRecord)
    }, [submissionEnabled, paper2SubmissionRecord])

    useEffect(() => {
        setPaper2ChoicesSubmissionEnabled(submissionEnabled && !paper2ChoicesSubmissionRecord)
    }, [submissionEnabled, paper2ChoicesSubmissionRecord])


    const loadSubmissionData = (currentSubmissionStatus) => {
        if (currentSubmissionStatus !== null) {
            // console.log("SUbmission status: ", currentSubmissionStatus)
            switch (currentSubmissionStatus.status) {
                case SUBMISSION_STATUS.OPEN:
                    setSubmissionEnabled(true)
                    break
                case SUBMISSION_STATUS.NOT_YET:
                case SUBMISSION_STATUS.CLOSED:
                case SUBMISSION_STATUS.PASSED:
                    setSubmissionEnabled(false)
                    break
                default:
                    break
            }
        }

        setSubmissionStatus(currentSubmissionStatus)
    }

    const loadSubjectData = (currentData) => {

        setValidationRecord(null)
        setPaper1SubmissionRecord(null)
        setPaper2SubmissionRecord(null)
        setPaper2ChoicesSubmissionRecord(null)

        console.log("Loading subject data: ", currentData)

        if (currentData) {
            console.log("loading records")
            setValidationRecord(currentData['proof_timestamp'])
            setPaper1SubmissionRecord(currentData['paper1_timestamp'])
            setPaper2SubmissionRecord(currentData['paper2_timestamp'])
            setPaper2ChoicesSubmissionRecord(currentData['paper2_choices_timestamp'])

        } else {
            setValidationRecord(null)
            setPaper1SubmissionRecord(null)
            setPaper2SubmissionRecord(null)
            setPaper2ChoicesSubmissionRecord(null)

        }

    }

    const handlePaper2ChoiceSubmit = async () => {
        if (paper2Choices.includes(-1) && !confirmAllowBlank) {
            // launchErrorAlert("Please fill in all the choices.")
            setOpenConfirmAllowBlankModal(true)
            return
        }

        const docRef = doc(db, `users/${uid}/submissions/${SUBJECTS.CORE}`)

        try {

            setIsSubmittingPaper2Choices(true)

            await setDoc(docRef, { paper2_choices: paper2Choices, paper2_choices_timestamp: serverTimestamp() }, { merge: true })
            launchSuccessAlert("Choices submitted.")

            setOpenPaper2ChoicesModal(false)

        } catch (e) {
            console.error(e)
        }
        finally {
            handlePaper2ChoicesConfirmAlertClose()
            setIsSubmittingPaper2Choices(false)
        }
    }


    // On tab switch
    useEffect(() => {

        // console.log("Tab value", tabValue)

        switch (tabValue) {
            case 0:
                loadSubjectData(coreData)
                loadSubmissionData(coreSubmissionStatus)
                break
            case 1:
                loadSubjectData(m1Data)
                loadSubmissionData(m1SubmissionStatus)

                break
            case 2:
                loadSubjectData(m2Data)
                loadSubmissionData(m2SubmissionStatus)
                break
            default:
                break
        }

    }, [tabValue, coreData, coreSubmissionStatus, m1Data, m1SubmissionStatus, m2Data, m2SubmissionStatus])

    const storage = getStorage();


    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileSource, setSelectedFileSource] = useState(null)
    const [isUploadingFile, setIsUploadingFile] = useState(false)


    const handleFileSelect = (files, source) => {

        if (files.length <= 0) {
            launchErrorAlert("Invalid file selected. Please check the file is of type PDF and is smaller than 10MB.")
            return
        }

        console.log("Files selected: ", files)

        const fileUploaded = files[0]

        if (fileUploaded.size > PROOF_SIZE_LIMIT) {
            launchErrorAlert("File too large. Please upload a file of size 5MB or smaller.")
            return
        }

        setSelectedFile({ data: fileUploaded, url: URL.createObjectURL(fileUploaded) })
        setSelectedFileSource(source)
        setOpenFilePreviewModal(true)

    }

    const handleFileUpload = async () => {


        if (uid === null) {
            launchErrorAlert()
            return
        }

        const subject = Object.values(SUBJECTS)[tabValue]

        const fileNameComponents = selectedFile.data.name.split(".")
        const fileExtension = fileNameComponents[fileNameComponents.length - 1]

        const fileRef = ref(storage, `submission/${subject}/${uid}_${subject}${selectedFileSource === FILE_SOURCE.PROOF ? "_proof" : subject === SUBJECTS.CORE ? `_${selectedFileSource}` : ""}.${fileExtension}`)
        const docRef = doc(db, `users/${uid}/submissions/${subject}`)

        setIsUploadingFile(true)

        try {
            await uploadBytes(fileRef, selectedFile.data, { contentType: selectedFile.data.type })
            console.log('Uploaded a blob or file!');

            const newDocObj = {}
            newDocObj[`${selectedFileSource}`] = fileRef.fullPath
            newDocObj[`${selectedFileSource}_timestamp`] = serverTimestamp()

            setDoc(docRef, newDocObj, { merge: true })

            launchSuccessAlert("File uploaded.")

            handleModalClose()

            // window.location.reload();

        } catch (e) {
            launchErrorAlert("File uplaod error. Please try again later.\nIf problem persists, please contact team member for technical support.")
            console.error(e)
        }
        finally {
            setIsUploadingFile(false)
        }
    }


    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState(null)
    const [errorAlertMessage, setErrorAlertMessage] = useState(null)

    const launchSuccessAlert = (message) => {
        setSuccessAlertMessage(message)
        setOpenSuccessAlert(true)
    }

    const launchErrorAlert = (message) => {
        setErrorAlertMessage(message)
        setOpenErrorAlert(true)
    }

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessAlert(false);
    };

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenErrorAlert(false);
    };


    const [openFilePreviewModal, setOpenFilePreviewModal] = useState(false)

    const handleModalClose = () => {
        setOpenFilePreviewModal(false)
        setSelectedFile(null)
        setSelectedFileSource(null)
        validationFileInputRef.current.value = ""
    }

    const [openPaper2ChoicesModal, setOpenPaper2ChoicesModal] = useState(false)
    const [openPaper2ChoicesConfirmAlert, setOpenPaper2ChoicesConfirmAlert] = useState(false)
    const [isSubmittingPaper2Choices, setIsSubmittingPaper2Choices] = useState(false)

    const [confirmAllowBlank, setConfirmAllowBlank] = useState(false)
    const [openConfirmAllowBlankModal, setOpenConfirmAllowBlankModal] = useState(false)

    const handlePaper2ChoicesModalClose = () => {
        setOpenPaper2ChoicesModal(false)

    }

    const handlePaper2ChoicesConfirmAlertOpen = () => {
        if (paper2Choices.includes(-1) && !confirmAllowBlank) {
            // launchErrorAlert("Please fill in all the choices.")
            setOpenConfirmAllowBlankModal(true)
            return
        }

        setOpenConfirmAllowBlankModal(false)
        setOpenPaper2ChoicesConfirmAlert(true)
    }


    const handlePaper2ChoicesConfirmAlertClose = () => {
        setOpenPaper2ChoicesConfirmAlert(false)
    }

    const handleConfirmAllowBlankModalClose = () => {
        setOpenConfirmAllowBlankModal(false)
    }


    const submissionStatusDescription = (submissionStatus) => {
        if (!submissionStatus) {
            return (<></>
            )
        }
        switch (submissionStatus.status) {

            case SUBMISSION_STATUS.CLOSED:
                return (<Typography
                    color="textSecondary"
                    variant="body2"
                    style={{ textAlign: "center" }}

                >
                    Closed
                </Typography>
                )
            case SUBMISSION_STATUS.NOT_YET:
                return (<Typography
                    color="textSecondary"
                    variant="body2"
                    style={{ textAlign: "center" }}
                >
                    {"Submission will open on " + moment(submissionStatus.toi).format(dateTimeDisplayFormat)}

                </Typography>
                )
            case SUBMISSION_STATUS.OPEN:
                return (<Typography
                    color="success.main"
                    variant="body2"
                    style={{ textAlign: "center" }}

                >
                    {"Submission open till " + moment(submissionStatus.toi).format(dateTimeDisplayFormat)}

                </Typography>
                )
            case SUBMISSION_STATUS.PASSED:
                return (<Typography
                    color="error"
                    variant="body2"
                    style={{ textAlign: "center" }}

                >
                    Submission Closed
                </Typography>
                )

            default:
                return (<></>)


        }
    }

    return (<DashboardLayout>
        {/* <Head>
          <title>
            Account | Material Kit
          </title>
        </Head> */}

        <DashboardNavbar />
        {!initialized ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
            <CircularProgress />
        </div > : <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                {/* <Typography
                    sx={{ mb: 3 }}
                    variant="h4"
                >
                    Account
                </Typography> */}

                {profileConfirmed ? !openPaper2ChoicesModal ?

                    <><MDBox
                        component="ul"
                        sx={({ breakpoints }) => ({
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                            listStyle: "none",
                            mt: 3,
                            mb: 0,
                            p: 0,
                            paddingBottom: 1,

                            [breakpoints.up("md")]: {
                                justifyContent: "flex-end",

                                mt: 0,
                            },
                        })}
                    >
                        <Tabs
                            value={tabValue} onChange={handleSetTabValue}>
                            <Tab
                                label="Core"
                            />
                            <Tab
                                label="M1"
                            />
                            <Tab
                                label="M2"
                            />
                        </Tabs>      </MDBox>

                        <Grid
                            container
                            spacing={3}
                            style={{ justifyContent: "flex-end" }}
                        >
                            <Grid
                                item
                                lg={4}
                                md={6}
                                xs={12}
                            >
                                <Card >
                                    <CardHeader
                                        // subheader="Please complete the following session"
                                        title="Status"
                                    />

                                    <CardContent>
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >

                                            {/* <img
                                                alt="Under development"
                                                src={Logo}
                                                style={{
                                                    margin: 24,
                                                    display: 'inline-block',
                                                    // maxWidth: '100%',
                                                    width: "60%"

                                                }}
                                            /> */}

                                            <Typography
                                                color="textSecondary"
                                                variant="body3"
                                            >
                                                Subject: {Object.values(SUBJECTS)[tabValue]}
                                            </Typography>


                                            {/* <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                Open for Submission
                                            </Typography> */}
                                            {submissionStatusDescription(submissionStatus)}




                                        </Box>
                                    </CardContent>
                                    <Box sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>

                                        <Typography
                                            color={validationRecord ? "success.main" : "error"}
                                            variant="overline"
                                        >
                                            {validationRecord ? `Validated on ${moment(validationRecord.toDate()).format(dateTimeDisplayFormat)}` : "Validation Required"}
                                        </Typography>


                                        {
                                            submissionCompleted ? <>

                                                <Typography
                                                    color="success.main"
                                                    variant="body1"

                                                    style={{
                                                        marginTop: 24
                                                    }}
                                                >
                                                    Submission Completed!
                                                </Typography>

                                                <Icon fontSize="large" color="success">check_circle</Icon>
                                            </> : submissionIncomplete ?
                                                <>

                                                    <Typography
                                                        color="warning"
                                                        variant="body1"

                                                        style={{
                                                            marginTop: 24
                                                        }}
                                                    >
                                                        Submission Incomplete
                                                    </Typography>

                                                    <Icon fontSize="large" color="warning">warning</Icon>
                                                </>
                                                : <></>
                                        }
                                    </Box>
                                    <Divider />
                                    <CardActions>
                                        <Tooltip title="Upload receipt to verify your eligibility">
                                            <Button
                                                color="primary"
                                                fullWidth
                                                variant="text"
                                                onClick={() => {
                                                    validationFileInputRef.current.click()
                                                }}
                                                disabled={!validationSubmissionEnabled}
                                            >
                                                Upload receipt
                                            </Button>
                                        </Tooltip>

                                        <input type="file" ref={validationFileInputRef} accept="image/*, application/pdf" style={{ display: 'none' }}
                                            onChange={(event) => { handleFileSelect(event.target.files, FILE_SOURCE.PROOF) }}
                                        />

                                    </CardActions>
                                </Card>
                            </Grid>
                            {tabValue === 0 ?
                                <>
                                    <Grid
                                        item
                                        lg={8}
                                        md={6}
                                        xs={12}
                                    >
                                        <Card>
                                            <CardHeader
                                                // subheader="Please complete the following session"
                                                title="Paper 1 Submission"
                                            />
                                            {/* <Divider /> */}
                                            <CardContent>
                                                <StyledDropzone disabled={!paper1SubmissionEnabled} onDrop={(files) => { handleFileSelect(files, FILE_SOURCE.PAPER1) }} accept="application/pdf" dropzoneRef={paper1FileInputRef} />

                                                <Typography
                                                    color={paper1SubmissionRecord ? "success.main" : "error"}
                                                    variant="body2"
                                                    style={{ paddingTop: 18 }}
                                                >

                                                    {paper1SubmissionRecord ? `File submitted on ${moment(paper1SubmissionRecord.toDate()).format(dateTimeDisplayFormat)}` : "No Submission Record"}

                                                </Typography>
                                            </CardContent>
                                            <Divider />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    p: 2
                                                }}
                                            >

                                                <MDButton variant="gradient"
                                                    color="info"
                                                    onClick={() => {
                                                        paper1FileInputRef.current.open()
                                                    }}
                                                    disabled={!paper1SubmissionEnabled}
                                                >
                                                    Submit
                                                </MDButton>
                                            </Box>
                                        </Card>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={8}
                                        md={6}
                                        xs={12}
                                    >
                                        <Card>
                                            <CardHeader
                                                // subheader="Please complete the following session"
                                                title="Paper 2 Submission"
                                            />
                                            {/* <Divider /> */}
                                            <CardContent>
                                                <StyledDropzone disabled={!paper2SubmissionEnabled} onDrop={(files) => { handleFileSelect(files, FILE_SOURCE.PAPER2) }} accept="application/pdf" dropzoneRef={paper2FileInputRef} />

                                                <Typography
                                                    color={paper2SubmissionRecord ? "success.main" : "error"}
                                                    variant="body2"
                                                    style={{ paddingTop: 18 }}
                                                >

                                                    {paper2SubmissionRecord ? `File submitted on ${moment(paper2SubmissionRecord.toDate()).format(dateTimeDisplayFormat)}` : "No file submission Record"}

                                                </Typography>

                                                <Typography
                                                    color={paper2ChoicesSubmissionRecord ? "success.main" : "error"}
                                                    variant="body2"
                                                    style={{ paddingTop: 18 }}
                                                >

                                                    {paper2ChoicesSubmissionRecord ? `Choices submitted on ${moment(paper2ChoicesSubmissionRecord.toDate()).format(dateTimeDisplayFormat)}` : "No choices submission Record"}

                                                </Typography>
                                            </CardContent>
                                            <Divider />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    p: 2
                                                }}
                                            >
                                                {/* <Button
                                    color="primary"
                                    variant="contained"
                                >
                                    Submit
                                </Button> */}
                                                <MDButton variant="gradient"
                                                    color="info"
                                                    onClick={() => {
                                                        setOpenPaper2ChoicesModal(true)
                                                    }}
                                                    disabled={!paper2ChoicesSubmissionEnabled}

                                                    style={{ marginRight: 12 }}
                                                >
                                                    Fill in Choices
                                                </MDButton>

                                                <MDButton variant="gradient"
                                                    color="info"
                                                    onClick={() => {
                                                        console.log("On click paper 2 submit")
                                                        paper2FileInputRef.current.open()
                                                    }}
                                                    disabled={!paper2SubmissionEnabled}
                                                >
                                                    Submit
                                                </MDButton>
                                            </Box>
                                        </Card>
                                    </Grid>
                                </> : <Grid
                                    item
                                    lg={8}
                                    md={6}
                                    xs={12}
                                >
                                    <Card>
                                        <CardHeader
                                            // subheader="Please complete the following session"
                                            title="Paper Submission"
                                        />
                                        {/* <Divider /> */}
                                        <CardContent>
                                            <StyledDropzone disabled={!paper1SubmissionEnabled} onDrop={(files) => { handleFileSelect(files, FILE_SOURCE.PAPER1) }} accept="application/pdf" dropzoneRef={paper1FileInputRef} />

                                            <Typography
                                                color={paper1SubmissionRecord ? "success.main" : "error"}
                                                variant="body2"
                                                style={{ paddingTop: 18 }}
                                            >

                                                {paper1SubmissionRecord ? `File submitted on ${moment(paper1SubmissionRecord.toDate()).format(dateTimeDisplayFormat)}` : "No Submission Record"}

                                            </Typography>
                                        </CardContent>
                                        <Divider />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                p: 2
                                            }}
                                        >
                                            {/* <Button
                                    color="primary"
                                    variant="contained"
                                >
                                    Submit
                                </Button> */}
                                            <MDButton variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    paper1FileInputRef.current.open()
                                                }}
                                                disabled={!paper1SubmissionEnabled}
                                            >
                                                Submit
                                            </MDButton>
                                        </Box>
                                    </Card>
                                </Grid>}

                        </Grid>
                    </>
                    :
                    <Grid
                        item
                        lg={8}
                        md={12}
                        xs={12}
                    >
                        <Card>
                            <CardHeader
                                subheader="Please fill in your choices in the grid below"
                                title="Paper 2 Multiple Choices"
                            />

                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                {/* <FormLabel style={{ marginRight: 24, display: 'flex', width: 24 }} ></FormLabel> */}
                                <div style={{ width: 47, height: 38, marginLeft: 18, textAlign: "center" }}>
                                    <Typography variant={"body1"}>
                                        A
                                    </Typography>
                                </div>
                                <div style={{ width: 47, height: 38, textAlign: "center" }}>
                                    <Typography variant={"body1"}>
                                        B
                                    </Typography>
                                </div>
                                <div style={{ width: 47, height: 38, textAlign: "center" }}>
                                    <Typography variant={"body1"}>
                                        C
                                    </Typography>
                                </div>
                                <div style={{ width: 47, height: 38, textAlign: "center" }}>
                                    <Typography variant={"body1"}>
                                        D
                                    </Typography>
                                </div>
                            </Box>
                            {Array.from(Array(45).keys()).map((index) => (
                                <FormControl key={index}>

                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <FormLabel style={{ marginRight: 24, display: 'flex', width: 24 }} >Q{index + 1}</FormLabel>
                                        <RadioGroup
                                            row
                                            name="row-radio-buttons-group"
                                            onChange={(event) => {
                                                console.log("Radio change: ", index, event.target.value)

                                                const newChoice = event.target.value

                                                const newChoices = paper2Choices
                                                newChoices[index] = newChoice
                                                setPaper2Choices(newChoices)

                                                console.log("Choices changed: ", newChoices)
                                            }}
                                        >

                                            {Array.from(Array(4).keys()).map((optionIndex) => (
                                                <FormControlLabel value={optionIndex} control={<Radio />} label="" key={`${index}_${optionIndex}`} />))}
                                        </RadioGroup>
                                    </Box>
                                </FormControl>

                            ))}

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <MDButton variant="gradient"
                                    color="secondary"
                                    onClick={handlePaper2ChoicesModalClose}
                                    style={{ marginRight: 12 }}
                                >
                                    Cancel
                                </MDButton>
                                <MDButton variant="gradient"
                                    color="info"
                                    onClick={handlePaper2ChoicesConfirmAlertOpen}
                                >
                                    Confirm
                                </MDButton>
                            </Box>
                        </Card>
                    </Grid>
                    :
                    <Card>
                        <CardHeader
                            subheader="Please complete your profile before submission"
                            title="Profile Not Complete"
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >
                            {/* <Button
                                    color="primary"
                                    variant="contained"
                                >
                                    Submit
                                </Button> */}
                            <MDButton variant="gradient"
                                color="info"
                                onClick={() => { navigate(PATH.PROFILE) }}
                            >
                                Complete Profile
                            </MDButton>
                        </Box>
                    </Card>
                }


            </Container>
        </Box>}

        <Footer />


        <Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={handleSuccessAlertClose}>
            <Alert onClose={handleSuccessAlertClose} severity="success" sx={{ width: '100%' }}>
                {successAlertMessage ? successAlertMessage : "Success"}
            </Alert>
        </Snackbar>

        <Snackbar open={openErrorAlert} autoHideDuration={6000} onClose={handleErrorAlertClose}>
            <Alert onClose={handleErrorAlertClose} severity="error" sx={{ width: '100%' }}>
                {errorAlertMessage ? errorAlertMessage : "Error Occuered"}
            </Alert>
        </Snackbar>

        <Modal
            open={openFilePreviewModal}
            onClose={handleModalClose}
            onBackdropClick={handleModalClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "80%",
                maxWidth: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}>
                <MDBox style={{ display: "flex", justifyContent: "center", marginBottom: 16 }}>
                    <Typography variant="h6" component="h2">
                        Upload Confirm
                    </Typography>

                </MDBox>
                <MDBox style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 16, marginBottom: 16, }}>

                    {selectedFile && selectedFile.data.type.includes("image") ? <img
                        style={{ width: "100%" }}
                        src={selectedFile && selectedFile.url}
                    /> : selectedFile && selectedFile.data.type.includes("pdf") ?

                        // <Document file={{ file: selectedFile.data }} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error} options={{
                        //     cMapUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps`,
                        //     cMapPacked: true,
                        // }}
                        // >
                        //     {Array.from(new Array(numPages), (el, index) => (
                        //         <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        //     ))}
                        // </Document>

                        <Typography variant="body1" style={{ textAlign: "center" }}> PDF: {selectedFile.data.name} </Typography>

                        : <Typography> Unknown file type </Typography>}
                    {/* <img
                    style={{ width: "100%" }}
                    src={selectedFile && selectedFile.url}
                /> */}
                    <Typography variant="body2" style={{ textAlign: "center", marginTop: 8 }} color="error"> Note: You can only submit once, please double check to make sure the file you submit is correct. </Typography>
                </MDBox>

                <MDBox style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    {isUploadingFile ? <CircularProgress /> : <>  <MDButton variant="gradient"
                        color="secondary"
                        onClick={handleModalClose}
                        style={{ margin: 4 }}
                    >
                        Cancel
                    </MDButton>

                        <MDButton variant="gradient"
                            color="info"
                            onClick={handleFileUpload}
                            style={{ margin: 4 }}

                        >
                            Confirm
                        </MDButton></>}


                </MDBox>


            </Box>
        </Modal>

        <Dialog
            open={openPaper2ChoicesConfirmAlert}
            onClose={handlePaper2ChoicesConfirmAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirm Paper 2 Choices"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please double check and make sure the choices are correct as you will not be able to make any modifications afterwards.
                </DialogContentText>
            </DialogContent>
            {isSubmittingPaper2Choices ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                <CircularProgress />
            </div > : <DialogActions>
                <Button onClick={handlePaper2ChoicesConfirmAlertClose}>Go Back</Button>
                <Button onClick={handlePaper2ChoiceSubmit} autoFocus>
                    Confirm
                </Button>
            </DialogActions>}

        </Dialog>

        <Dialog
            open={openConfirmAllowBlankModal}
            onClose={handleConfirmAllowBlankModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirm Allow Blank"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    There are unanswered questions in your attemp. Are you sure you want to proceed?
                    Click "CONFIRM" TWICE to confirm your choice.
                </DialogContentText>
            </DialogContent>
            {isSubmittingPaper2Choices ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                <CircularProgress />
            </div > : <DialogActions>
                <Button onClick={handleConfirmAllowBlankModalClose}>Go Back</Button>
                <Button onClick={() => {
                    setConfirmAllowBlank(true)
                    handlePaper2ChoicesConfirmAlertOpen()
                }} autoFocus>
                    Confirm
                </Button>
            </DialogActions>}

        </Dialog>


        {/* <Modal
            open={openPaper2ChoicesModal}
            onClose={handlePaper2ChoicesModalClose}
            onBackdropClick={handlePaper2ChoicesModalClose}
        >

            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                minWidth: 500,
                width: "60%",
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}>
                <MDBox style={{ display: "flex", justifyContent: "center", marginBottom: 16 }}>
                    <Typography variant="h6" component="h2">
                        Fill in Paper 2 Choices
                    </Typography>

                </MDBox>
                <MDBox style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 16, marginBottom: 16, }}>
                    <FormControl>
                        {Array.from(Array(45).keys()).map((index) => (
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                <FormLabel style={{ marginRight: 8, display: 'flex', width: 24 }} >Q{index + 1}</FormLabel>
                                <RadioGroup
                                    row
                                    name="row-radio-buttons-group"
                                // style={{ flex: 8 }}
                                >
                                    <FormControlLabel value="A" control={<Radio />} label="" />
                                    <FormControlLabel value="B" control={<Radio />} label="" />
                                    <FormControlLabel value="C" control={<Radio />} label="" />
                                    <FormControlLabel value="D" control={<Radio />} label="" />
                                </RadioGroup>
                            </Box>
                        ))}


                    </FormControl>
                </MDBox>

                <MDBox style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    {isUploadingFile ? <CircularProgress /> : <>  <MDButton variant="gradient"
                        color="secondary"
                        onClick={handleModalClose}
                        style={{ margin: 4 }}
                    >
                        Cancel
                    </MDButton>

                        <MDButton variant="gradient"
                            color="info"
                            onClick={handleFileUpload}
                            style={{ margin: 4 }}

                        >
                            Confirm
                        </MDButton></>}


                </MDBox>


            </Box>
        </Modal> */}
    </DashboardLayout >)
} 