
import { useState, useEffect } from "react"

import { useNavigate } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";


import ProgressOverview from "components/dashboard/ProgressOverview";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import { RootContainer } from '../components';

import { spacing } from "../styles"

import { PATH, SUBJECTS, SUBMISSION_STATUS } from '../constants'

import { getFirestore } from "firebase/firestore"
import { collection, query, where, getDocs } from "firebase/firestore";

import moment from "moment";
import { parseSubmissionStatus } from "utils";


export function Dashboard() {

    const navigate = useNavigate()

    const db = getFirestore();

    const [tikTok, setTikTok] = useState(true)

    const dateTimeDisplayFormat = "D MMM HH:mm"

    const timeframeColRef = "timeframe"

    const [coreSubmissionStatus, setCoreSubmissionStatus] = useState(null)
    const [m1SubmissionStatus, setM1SubmissionStatus] = useState(null)
    const [m2SubmissionStatus, setM2SubmissionStatus] = useState(null)


    useEffect(() => {
        getDocs(collection(db, timeframeColRef))
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, doc.data())

                    const deadline = doc.data()["submissionDeadline"].toDate()

                    switch (doc.id) {
                        case SUBJECTS.CORE:
                            setCoreSubmissionStatus(parseSubmissionStatus(doc.data()))
                            break
                        case SUBJECTS.M1:
                            setM1SubmissionStatus(parseSubmissionStatus(doc.data()))
                            break
                        case SUBJECTS.M2:
                            setM2SubmissionStatus(parseSubmissionStatus(doc.data()))
                            break
                        default:
                            break
                    }
                })
            })

    }, [])

    // const updateTikTok = () => {
    //     setTikTok(!tikTok)
    //     console.log("Updateding deadline", tikTok, !tikTok)

    // }

    // useEffect(() => {
    //     const timer = setInterval(() => {

    //         updateTikTok()

    //     }, 1000);

    //     return () => clearInterval(timer);

    // }, []);


    const dashboardItems = [
        {
            title: "Step 0 - Confirm Personal Information",
            status: "Incomplete",
            details: "Fill in your personal information and upload an image for your student ID",
            buttonLabel: "Complete Now",
            onClick: () => {
                navigate(PATH.PROFILE)
            },
            completed: false
        }, {
            title: "Step 1 - Submit your mock",
            status: "Core submission open",
            details: "Submit your paper",
            buttonLabel: "Submit",
            onClick: () => {
                navigate(PATH.SUBMISSION)
            }
        }, {
            title: "Step 2 - View results",
            status: "Pending",
            details: "Your paper is still under review",
            buttonLabel: "View",
        },
    ]


    const submissionStatusCard = (subject, parseSubmissionStatus) => {
        if (!parseSubmissionStatus) {
            return (<ComplexStatisticsCard
                color="dark"
                icon="calculate"
                title={`${subject} Status`}
                count={"-"}
                percentage={{
                    // color: corePassDue ? "success" : "error",
                    // amount: corePassDue ? moment(coreTimeframe).fromNow() : "Pass Due",
                    label: "-",
                }}
            />)
        }

        switch (parseSubmissionStatus.status) {

            case SUBMISSION_STATUS.CLOSED:
                return (<ComplexStatisticsCard
                    color="dark"
                    icon="calculate"
                    title={`${subject} Status`}
                    count={"Closed"}
                    percentage={{
                        // color: corePassDue ? "success" : "error",
                        // amount: corePassDue ? moment(coreTimeframe).fromNow() : "Pass Due",
                        label: "-",
                    }}
                />)
            case SUBMISSION_STATUS.NOT_YET:
                return (<ComplexStatisticsCard
                    color="dark"
                    icon="calculate"
                    title={`${subject} Status`}
                    count={"Waiting"}
                    percentage={{
                        color: "success",
                        amount: moment(parseSubmissionStatus.toi).fromNow(),
                        label: "Opens",
                        detail: moment(parseSubmissionStatus.toi).format(dateTimeDisplayFormat)
                    }}
                />)
            case SUBMISSION_STATUS.OPEN:
                return (<ComplexStatisticsCard
                    color="info"
                    icon="calculate"
                    title={`${subject} Status`}
                    count={"Open"}
                    percentage={{
                        color: "success",
                        amount: moment(parseSubmissionStatus.toi).fromNow(),
                        label: "Closes",
                        detail: moment(parseSubmissionStatus.toi).format(dateTimeDisplayFormat)

                    }}
                />)
            case SUBMISSION_STATUS.PASSED:
                return (<ComplexStatisticsCard
                    color="error"
                    icon="calculate"
                    title={`${subject} Status`}
                    count={"Closed"}
                    percentage={{
                        color: "error",
                        amount: "Passed Due",
                        // label: "-",
                    }}
                />)

            default:
                return (<></>)
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />


            {/* <RootContainer>
            <div style={{
                width: "80%",
                maxWidth: 1000,
                minWidth: 250,
                // padding: spacing.regular,

            }}> */}
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            {submissionStatusCard("Core", coreSubmissionStatus)}
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            {submissionStatusCard("M1", m1SubmissionStatus)}

                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            {submissionStatusCard("M2", m2SubmissionStatus)}

                        </MDBox>
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="primary"
                                icon="person_add"
                                title="Followers"
                                count="+91"
                                percentage={{
                                    color: "success",
                                    amount: "",
                                    label: "Just updated",
                                }}
                            />
                        </MDBox>
                    </Grid> */}
                </Grid>
                {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
                {/* <MDBox>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <ProgressOverview />
                        </Grid>
                    </Grid>
                </MDBox> */}
            </MDBox>


            {/* {dashboardItems.map((item) => {
                return (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                                <Typography sx={{ flex: 4, flexShrink: 0, textAlign: "left", }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{ flex: 6, color: 'text.secondary', textAlign: "left" }}>
                                    {item.status}
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ textAlign: "left", flex: 4 }}>
                                    {item.details}
                                </Typography>

                                <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                                    <Button variant="contained" disabled={!item.onClick} onClick={item.onClick}>
                                        {item.buttonLabel}
                                    </Button>
                                </div>

                            </div>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
 */}
            {/* </div>

        </RootContainer> */}

            <Footer />
        </DashboardLayout>

    )
}