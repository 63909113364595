import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export function StyledDropzone(props) {
    let { disabled, dropzoneRef } = props

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        // inputRef
    } = useDropzone({ accept: 'image/*' });



    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused && !disabled ? focusedStyle : {}),
        ...(isDragAccept && !disabled ? acceptStyle : {}),
        ...(isDragReject && !disabled ? rejectStyle : {}),
        textAlign: "center"
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        // <div className="container">
        //     <div {...getRootProps({ style })}>
        //         <input {...getInputProps({ ref: dropzoneRef })} {...props} />
        //         <p>{disabled ? "Dropzone disabled" : "Drag 'n' drop some files here, or click to select files"}</p>
        //     </div>
        // </div>

        <Dropzone ref={dropzoneRef} {...props} maxSize={10485760} >
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ style })} >
                    <input {...getInputProps()} />
                    <p>{disabled ? "Dropzone disabled" : "Drag 'n' drop some files here, or click to select files "}</p>
                    <em>{disabled ? "" : "(Only PDF files less than 10MB will be accepted)"}</em>

                </div>
            )}
        </Dropzone>

    );
}

<StyledDropzone />