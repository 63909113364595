import { useState, useEffect, createRef } from 'react';

import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    Typography,
    TextField,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


import { Snackbar, Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

import { useNavigate } from 'react-router-dom';


import Icon from "@mui/material/Icon";


// import { AccountProfile } from '../components/account/account-profile';
// import { AccountProfileDetails } from '../components/account/account-profile-details';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { getAuth, updateProfile } from "firebase/auth";
import { getFirestore, doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";

import { getStorage, ref, uploadBytes } from "firebase/storage";


import MDButton from 'components/MDButton';
import { spacing } from 'styles';
import MDBox from 'components/MDBox';


import { Document, Page, pdfjs } from 'react-pdf';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import validate from "validate.js"
import { textAlign } from '@mui/system';

const STUDENT_ID_SIZE_LIMIT = 5000000

const fileInputRef = createRef()

export function Profile() {

    const navigate = useNavigate()

    const db = getFirestore();

    const auth = getAuth();
    const user = auth.currentUser;
    let uid = null;

    if (user !== null) {
        // The user object has basic properties such as display name, email, etc.
        const displayName = user.displayName;
        const email = user.email;
        const photoURL = user.photoURL;
        const emailVerified = user.emailVerified;

        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.

        uid = user.uid
    }

    console.log("Auth User: ", user)

    const [values, setValues] = useState({
        firstName: null,
        lastName: null,
        email: null,
        mobile: null,
    })

    // const [firstName, setFirstName] = useState(null)
    // const [lastName, setLastName] = useState(null)
    // const [email, setEmail] = useState(null)
    // const [mobile, setMobile] = useState(null)

    const userDocRef = doc(db, 'users', uid)


    const [readingDB, setReadingDB] = useState(true)

    const [profileConfirmed, setProfileConfirmed] = useState(false)
    const [idUploaded, setIdUploaded] = useState(false)

    const [isUploading, setIsUploading] = useState(false)

    const [openProfileConfirmAlert, setOpenProfileConfirmAlert] = useState(false)


    const handleProfileConfirmAlertOpen = () => {

        // Validate input before confirming

        const validateResult = validateProfile(values)

        if (validateResult) {
            console.log("Validate ", Object.values(validateResult))

            launchErrorAlert(Object.values(validateResult)[0][0])
            return
        }

        setOpenProfileConfirmAlert(true)
    }

    const handleProfileConfirmAlertClose = () => {
        setOpenProfileConfirmAlert(false)
    }

    const validateProfile = (values) => {
        return validate(values,
            {
                firstName: {
                    presence: { allowEmpty: false },
                    length: {
                        minimum: 2,
                    }

                },
                lastName: {
                    presence: { allowEmpty: false },
                    length: {
                        minimum: 2,
                    }

                },
                email: {
                    presence: { allowEmpty: false },
                    email: true,
                },
                mobile: {
                    presence: { allowEmpty: false },
                    length: {
                        is: 8,
                    }

                },
                beaconId: {
                    presence: { allowEmpty: false },
                    length: {
                        is: 6,
                    }

                },
                school: {
                    presence: { allowEmpty: false },
                    length: {
                        minimum: 2,
                    }

                },
                // form: {
                //     inclusion: {
                //         within: { "S1 -3": "S1 -3", "S4": "S4", "S5": "S6", "S6": "S5", "Others": "Others" },
                //         message: "^We're currently out of %{value}"
                //     }

                // }
            }
        )
    }

    useEffect(() => {
        // getDoc(userDocRef)
        // .then()
        const unsub = onSnapshot(userDocRef, (docSnap) => {
            if (docSnap.exists()) {

                // Read data from user doc
                const data = docSnap.data()

                console.log("Snapshot data received!")
                // Load firestore value only on first run
                if (readingDB) {
                    setValues({
                        firstName: data["firstName"],
                        lastName: data["lastName"],
                        email: data["email"],
                        mobile: data["mobile"],
                        beaconId: data['beaconId'],
                        school: data['school'],
                        // form: data['form'],

                    })
                }


                if (data["firstName"] && data["lastName"] && data["email"] && data["mobile"] && data['beaconId'] && data['school']) { setProfileConfirmed(true) } else {
                    setProfileConfirmed(false)
                }

                if (data["studentID"]) { setIdUploaded(true) } else {
                    setIdUploaded(false)
                }

                // setFirstName(data["firstName"])
                // setLastName(data['lastName'])
                // setEmail(data["email"])
                // setMobile(data["mobile"])

            } else {
                setValues({
                    firstName: user.displayName ? user.displayName.split(" ")[0] : "",
                    lastName: user.displayName ? user.displayName.split(" ")[1] : "",
                    email: user.email ? user.email : "",
                    mobile: "",
                    beaconId: "",
                    school: "",
                })

                console.log("User not exist")

                setProfileConfirmed(false)
                setIdUploaded(false)
            }

            setReadingDB(false)
        })

        return () => unsub()
    }, [])

    const [file, setFile] = useState(null)
    const [numPages, setNumPages] = useState(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false)

    const storage = getStorage();


    const handleInputFieldChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });

        console.log("Values: ", values)
    };

    const handleFileInputChange = (event) => {
        console.log("File Input Event: ", event, event.target.value, event.target.files)
        const fileUploaded = event.target.files[0]
        if (fileUploaded.size > STUDENT_ID_SIZE_LIMIT) {
            launchErrorAlert("File too large. Please upload a file of size 5MB or smaller.")
            return
        }
        setFile({ data: fileUploaded, url: URL.createObjectURL(fileUploaded) })
        setOpenFilePreviewModal(true)
    }

    const handleFileUpload = async () => {


        if (uid === null) {
            launchErrorAlert()
        }



        const fileNameComponents = file.data.name.split(".")
        const fileExtension = fileNameComponents[fileNameComponents.length - 1]
        const studentIdFileRef = ref(storage, `users/${uid}/${uid}_student_ID.${fileExtension}`)


        setIsUploadingFile(true)

        try {
            const snapshot = await uploadBytes(studentIdFileRef, file.data, { contentType: file.data.type })
            console.log('Uploaded a blob or file!');

            setDoc(userDocRef, { studentID: studentIdFileRef.fullPath }, { merge: true })

            launchSuccessAlert("Student ID uploaded.")

            handleModalClose()
        } catch (e) {
            launchErrorAlert("Student ID uplaod error. Please try again later.\nIf problem persists, please contact team member for technical support.")

        }
        finally {
            setIsUploadingFile(false)
        }
    }


    const [idUploadEnabled, setIdUploadEnabled] = useState(true)
    const [profileConfirmEnabled, setProfileConfirmEnabled] = useState(true)

    useEffect(() => {
        setIdUploadEnabled(!idUploaded)
    }, [idUploaded])

    useEffect(() => {
        setProfileConfirmEnabled(!profileConfirmed)
    }, [profileConfirmed])

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState(null)
    const [errorAlertMessage, setErrorAlertMessage] = useState(null)

    const launchSuccessAlert = (message) => {
        setSuccessAlertMessage(message)
        setOpenSuccessAlert(true)
    }

    const launchErrorAlert = (message) => {
        setErrorAlertMessage(message)
        setOpenErrorAlert(true)
    }

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessAlert(false);
    };

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenErrorAlert(false);
    };

    const [openFilePreviewModal, setOpenFilePreviewModal] = useState(false)


    const handleModalClose = () => {
        setOpenFilePreviewModal(false)
        setFile(null)
        fileInputRef.current.value = ""
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    const confirmProfile = async () => {

        if (uid === null) {
            launchErrorAlert()
        }

        setIsUploading(true)

        console.log("Values: ", values)

        try {
            await setDoc(userDocRef, values, { merge: true })

            console.log("User in confirm", auth, user)

            await updateProfile(user, { displayName: `${values['firstName']} ${values['lastName']}` })


            launchSuccessAlert("Profile Confirmed.")
            handleProfileConfirmAlertClose()
        }
        catch (e) {
            // Error Handling
            launchErrorAlert("Profile confirm error. Please try again later.\nIf problem persists, please contact team member for technical support.")
            console.error(e)
        }
        finally {
            setIsUploading(false)
        }
    }

    return (<DashboardLayout>
        {/* <Head>
          <title>
            Account | Material Kit
          </title>
        </Head> */}

        <DashboardNavbar />
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                {/* <Typography
                    sx={{ mb: 3 }}
                    variant="h4"
                >
                    Account
                </Typography> */}
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xs={12}
                    >
                        <Card >
                            <CardContent>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center'
                                    }}
                                >

                                    {readingDB ? <CircularProgress /> :
                                        <>
                                            <Avatar
                                                // src={user.avatar}
                                                sx={{
                                                    height: 64,
                                                    mb: 2,
                                                    width: 64
                                                }}
                                            />
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h5"
                                            >
                                                {user.displayName}
                                            </Typography>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {profileConfirmed ? <Icon color="success">check</Icon> : <Icon color="error">warning</Icon>}
                                                {" "}
                                                {profileConfirmed ? "Profile Confirmed" : "Profile Unconfirmed"}
                                            </Typography>

                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {idUploaded ? <Icon color="success">check</Icon> : <Icon color="error">warning</Icon>}
                                                {" "}
                                                {idUploaded ? "Student ID Uploaded" : "Student ID Missing"}
                                            </Typography>


                                            {
                                                idUploaded && profileConfirmed &&

                                                <>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                        style={{ margin: 16 }}
                                                    >
                                                        You may proceed to the submission section.
                                                    </Typography>
                                                    <MDButton variant="gradient"
                                                        color="info"
                                                        onClick={() => {
                                                            navigate("/submission")
                                                        }}
                                                    >
                                                        Submission
                                                    </MDButton>
                                                </>
                                            }


                                        </>
                                    }


                                </Box>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Button
                                    color="primary"
                                    fullWidth
                                    variant="text"
                                    onClick={() => {
                                        fileInputRef.current.click()
                                    }}
                                    disabled={!idUploadEnabled}
                                >
                                    Upload Student ID
                                </Button>
                                <input type="file" ref={fileInputRef} accept="image/*, application/pdf" style={{ display: 'none' }} onChange={handleFileInputChange} />

                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        md={6}
                        xs={12}
                    >
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <Card>
                                <CardHeader
                                    // subheader="Please complete the following session"
                                    title="Profile"
                                />
                                {/* <Divider /> */}

                                <CardContent>

                                    {readingDB ? <CircularProgress /> :
                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    // helperText="Please specify the first name"
                                                    label="First Name"
                                                    name="firstName"
                                                    placeholder='Tai Man'
                                                    onChange={handleInputFieldChange}
                                                    required
                                                    value={values.firstName}
                                                    // defaultValue={"First Name"}
                                                    variant="outlined"
                                                    disabled={!profileConfirmEnabled}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Last Name"
                                                    name="lastName"
                                                    placeholder='Chan'
                                                    onChange={handleInputFieldChange}
                                                    required
                                                    value={values.lastName}
                                                    // defaultValue={"Last Name"}
                                                    variant="outlined"
                                                    disabled={!profileConfirmEnabled}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Email Address"
                                                    name="email"
                                                    placeholder='taimanchan@gmail.com'
                                                    onChange={handleInputFieldChange}
                                                    required
                                                    value={values.email}
                                                    // defaultValue={"Email Address"}
                                                    variant="outlined"
                                                    disabled={!profileConfirmEnabled}

                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Mobile"
                                                    name="mobile"
                                                    placeholder='88888888'
                                                    onChange={handleInputFieldChange}
                                                    type="number"
                                                    required
                                                    value={values.mobile}
                                                    // defaultValue={"00000000"}
                                                    variant="outlined"
                                                    disabled={!profileConfirmEnabled}

                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Beacon ID"
                                                    name="beaconId"
                                                    placeholder='888888'
                                                    onChange={handleInputFieldChange}
                                                    type="number"
                                                    required
                                                    value={values.beaconId}
                                                    // defaultValue={"00000000"}
                                                    variant="outlined"
                                                    disabled={!profileConfirmEnabled}

                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="School Name (Full Name)"
                                                    placeholder='NA for Private Candidates'
                                                    name="school"
                                                    onChange={handleInputFieldChange}
                                                    required
                                                    value={values.school}
                                                    // defaultValue={"00000000"}
                                                    variant="outlined"
                                                    disabled={!profileConfirmEnabled}

                                                />
                                            </Grid>
                                            {/* <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            // style={{ backgroundColor: "#00f" }}
                                            >

                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Age *</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"

                                                        value={values.form || ""}
                                                        label="Form"
                                                        name='form'
                                                        onChange={handleInputFieldChange}
                                                        style={{
                                                            // backgroundColor: "#0f0",
                                                            height: "100%", paddingTop: 12, paddingBottom: 12, paddingLeft: 2
                                                        }}

                                                    >
                                                        <MenuItem value={"S1 -3"}>S1 - 3</MenuItem>
                                                        <MenuItem value={"S4"}>S4</MenuItem>
                                                        <MenuItem value={'S5'}>S5</MenuItem>
                                                        <MenuItem value={"S6"}>S6</MenuItem>
                                                        <MenuItem value={"Others"}>Others</MenuItem>
                                                    </Select>
                                                </FormControl>

                                            </Grid> */}
                                        </Grid>

                                    }


                                    {/* <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Country"
                                name="country"
                                onChange={handleInputFieldChange}
                                required
                                value={values.country}
                                variant="outlined"
                            />
                        </Grid> */}
                                    {/* <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Select State"
                  name="state"
                  onChange={handleInputFieldChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.state}
                  variant="outlined"
                >
                  {states.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid> */}
                                </CardContent>
                                <Divider />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        p: 2
                                    }}
                                >

                                    <MDButton variant="gradient"
                                        color="info"
                                        onClick={handleProfileConfirmAlertOpen}
                                        disabled={!profileConfirmEnabled}
                                    >
                                        Confirm
                                    </MDButton>

                                </Box>
                            </Card>
                        </form>                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Footer />
        <Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={handleSuccessAlertClose}>
            <Alert onClose={handleSuccessAlertClose} severity="success" sx={{ width: '100%' }}>
                {successAlertMessage ? successAlertMessage : "Success"}
            </Alert>
        </Snackbar>

        <Snackbar open={openErrorAlert} autoHideDuration={6000} onClose={handleErrorAlertClose}>
            <Alert onClose={handleErrorAlertClose} severity="error" sx={{ width: '100%' }}>
                {errorAlertMessage ? errorAlertMessage : "Error Occuered"}
            </Alert>
        </Snackbar>

        <Modal
            open={openFilePreviewModal}
            onClose={handleModalClose}
            onBackdropClick={handleModalClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "80%",
                maxWidth: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}>
                <MDBox style={{ display: "flex", justifyContent: "center", marginBottom: 16 }}>
                    <Typography variant="h6" component="h2">
                        Upload Confirm
                    </Typography>
                </MDBox>

                <MDBox style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 16, marginBottom: 16, }}>

                    {file && file.data.type.includes("image") ? <img
                        style={{ width: "100%" }}
                        src={file && file.url}
                    /> : file && file.data.type.includes("pdf") ?

                        // <Document file={{ file: file.data }} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error} options={{
                        //     cMapUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps`,
                        //     cMapPacked: true,
                        // }}
                        // >
                        //     {Array.from(new Array(numPages), (el, index) => (
                        //         <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        //     ))}
                        // </Document>
                        <>
                            <Typography variant="body1" style={{ textAlign: "center" }}> PDF: {file.data.name} </Typography>


                        </>
                        : <Typography> Unknown file type </Typography>}
                    {/* <img
                    style={{ width: "100%" }}
                    src={file && file.url}
                /> */}
                    <Typography variant="body2" style={{ textAlign: "center", marginTop: 8 }} color="error"> Note: You can only submit once, please double check to make sure the file you submission is correct. </Typography>
                </MDBox>

                <MDBox style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    {isUploadingFile ? <CircularProgress /> : <>  <MDButton variant="gradient"
                        color="secondary"
                        onClick={handleModalClose}
                        style={{ margin: 4 }}
                    >
                        Cancel
                    </MDButton>

                        <MDButton variant="gradient"
                            color="info"
                            onClick={handleFileUpload}
                            style={{ margin: 4 }}

                        >
                            Confirm
                        </MDButton></>}


                </MDBox>


            </Box>
        </Modal>

        <Dialog
            open={openProfileConfirmAlert}
            onClose={handleProfileConfirmAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirm Profile Information"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please double check and make sure the information is correct as they will be used for contacting you should there be any issues regregarding your mock submission.
                    You will not be able to modify the above information after confirmation.
                </DialogContentText>
            </DialogContent>
            {isUploading ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                <CircularProgress />
            </div > : <DialogActions>
                <Button onClick={handleProfileConfirmAlertClose}>Go Back</Button>
                <Button onClick={confirmProfile} autoFocus>
                    Confirm
                </Button>
            </DialogActions>}

        </Dialog>


    </DashboardLayout>)
} 