export * from "./path"

export const SUBJECTS = {
    CORE: "Core",
    M1: "M1",
    M2: "M2",
}

export const SUBMISSION_STATUS = {
    PASSED: -1,
    OPEN: 0,
    NOT_YET: 1,
    CLOSED: 2,
}