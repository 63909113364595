
import { SUBMISSION_STATUS } from "constants"

export const parseSubmissionStatus = (timeframe) => {
    let { submissionOpen, submissionStart, submissionDeadline } = timeframe

    // Convert from firebase to js date time
    submissionStart = submissionStart.toDate()
    submissionDeadline = submissionDeadline.toDate()

    if (!submissionOpen) {
        return { status: SUBMISSION_STATUS.CLOSED }
    } else {
        if (new Date().getTime() > new Date(submissionDeadline).getTime()) {
            // Passed Due
            return { status: SUBMISSION_STATUS.PASSED, toi: submissionDeadline }
        } else {
            if (new Date(submissionStart).getTime() > new Date().getTime()) {
                // Not Yet
                return { status: SUBMISSION_STATUS.NOT_YET, toi: submissionStart }
            } else {
                // Open
                return { status: SUBMISSION_STATUS.OPEN, toi: submissionDeadline }
            }

        }

    }
}